import ApiService from './apiService';
import MainService from './mainService';


class UserService extends MainService {
    constructor() {
        super();
    }

    async getUserAddresses(id) {
        return await ApiService.post('api/v1/customer/addresses', {
            params: {
                id
            }
        });
    }

    async updateUserName(userData) {
        return await ApiService.post('api/v1/customer/update-name', {
            params: {
                "id": userData.id,
                "name": userData.name,

            }
        });
    }

    async updateUserPhone(userData) {
        return await ApiService.post('api/v1/customer/update-phone', {
            params: {
                "id": userData.id,
                "phone": userData.phone,

            }
        });
    }

    async updateUserPhone(userData) {
        return await ApiService.post('api/v1/customer/update-phone', {
            params: {
                "id": userData.id,
                "phone": userData.phone,

            }
        });
    }

    async deleteAccount(id) {
        return await ApiService.post('api/v1/delete', {
            params: {
                "id": id,
            }
        });
    }

    async setDefaultAddress(address) {

        return await ApiService.post('api/v1/sales/address/set_default', {
            params: {
                "id": address.id,
                "customer_id": address.customer_id,

            }
        });
    }


    async deleteAddress(id) {

        return await ApiService.post('api/v1/customer/address/delete', {
            params: {
                "id": id,

            }
        });
    }

    async addNewUserAddress(address) {

        return await ApiService.post('api/v1/customer/address', {
            params: {
                "id": address.user_id,
                "name": address.name,
                "phone": address.phone,
                "street": address.street,
                "street2": "",
                "city": address.city,
                "address_type": "home",
                "is_default_add": true,
                "partner_latitude": address.lat,
                "partner_longitude": address.lng
            }
        });
    }
}



export default new UserService();