<script setup>
import DialogModal from "./DialogModal.vue";

import { computed } from "vue";
const props = defineProps({
    orderData: Object
});

const emit = defineEmits(["close", "show", "showCanceledModal"]);

const closeModal = () => {
    emit("close");
};
const show = async () => {
    emit("show");
};
const showCanceledModal = async () => {
    emit("showCanceledModal");
};


</script>
<template>
<div v-if="show" class="fixed inset-0 z-50 bg-black bg-opacity-50" @click="closeModal"></div>
<div v-if="show"
    class="modal-container fixed inset-x-0 bottom-0 z-50 bg-white rounded-t-lg shadow-lg transform transition-transform duration-700 ease-in-out"
    :class="{ 'translate-y-0': show, 'translate-y-full': !show }" dir="rtl">
    <div>

    </div>
    <div class="w-full h-[52px] absolute left-0 top-0 overflow-hidden bg-[#408e58] rounded-tl-lg rounded-tr-lg">
        <div class="flex flex-row-reverse justify-between items-center mr-8 h-full">
            <button @click="closeModal" class="focus:outline-none ml-6">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="w-7 h-7 cursor-pointer" preserveAspectRatio="none">
                    <mask id="mask0_78_14371" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="1" y="2" width="25"
                        height="25">
                        <rect x="1.55151" y="2.03711" width="24" height="24" fill="#D9D9D9"></rect>
                    </mask>
                    <g mask="url(#mask0_78_14371)">
                        <path
                            d="M13.5515 15.4369L8.6515 20.3369C8.46817 20.5202 8.23483 20.6119 7.9515 20.6119C7.66817 20.6119 7.43483 20.5202 7.2515 20.3369C7.06817 20.1536 6.9765 19.9202 6.9765 19.6369C6.9765 19.3536 7.06817 19.1202 7.2515 18.9369L12.1515 14.0369L7.2515 9.13691C7.06817 8.95358 6.9765 8.72025 6.9765 8.43691C6.9765 8.15358 7.06817 7.92025 7.2515 7.73691C7.43483 7.55358 7.66817 7.46191 7.9515 7.46191C8.23483 7.46191 8.46817 7.55358 8.6515 7.73691L13.5515 12.6369L18.4515 7.73691C18.6348 7.55358 18.8682 7.46191 19.1515 7.46191C19.4348 7.46191 19.6682 7.55358 19.8515 7.73691C20.0348 7.92025 20.1265 8.15358 20.1265 8.43691C20.1265 8.72025 20.0348 8.95358 19.8515 9.13691L14.9515 14.0369L19.8515 18.9369C20.0348 19.1202 20.1265 19.3536 20.1265 19.6369C20.1265 19.9202 20.0348 20.1536 19.8515 20.3369C19.6682 20.5202 19.4348 20.6119 19.1515 20.6119C18.8682 20.6119 18.6348 20.5202 18.4515 20.3369L13.5515 15.4369Z"
                            fill="white"></path>
                    </g>
                </svg>
            </button>
            <p class="text-lg font-semibold text-white">
                رقم الطلب #{{ orderData.id }}
            </p>
        </div>
    </div>

    <div class="px-6 py-4 mt-[52px]">
        <div class="flex flex-row-reverse justify-between items-center w-full">
            <p class="text-xs font-bold text-[#408e58]">{{ orderData.state }}</p>
            <p class="text-base font-bold text-[#408e58]">المنتجات</p>
        </div>

        <div class="space-y-4 mt-2">
            <div v-for="(product, index) in orderData.order_lines" :key="index"
                class="flex flex-row-reverse justify-end items-center gap-2">
                <div class="flex flex-col justify-start flex-grow ">
                    <p class="w-[276px] text-xs font-bold text-right text-[#515151]">
                        {{ product.product }} {{ product.qty }}x
                    </p>
                    <p class="w-[276px] text-xs font-semibold text-right text-[#408e58]">
                        <span class="ml-1"> {{ product.price }} </span>
                        <span>ريال</span>
                    </p>
                </div>
                <img :src="product.image
                    ? 'data:image/png;base64,' + product.image
                    : '/public/img/itemImage.png'
                    " alt="Product Image" class="w-[58px] h-[58px] rounded-xl object-cover" />
            </div>
        </div>
        <p class="mt-6 text-base border-t-[1px] border-[#d4d4d4] font-bold text-[#408e58] text-right">
            عنوان التوصيل
        </p>
        <div class="flex flex-row-reverse justify-end items-center gap-1 mt-2">
            <p class="w-[212px] text-xs font-semibold text-right text-[#515151]">
                {{ orderData.address.street }}
            </p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="flex-grow-0 flex-shrink-0 w-6 h-6" preserveAspectRatio="none">
                <mask id="mask0_78_14397" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24"
                    height="24">
                    <rect width="24" height="24" fill="#D9D9D9"></rect>
                </mask>
                <g mask="url(#mask0_78_14397)">
                    <path
                        d="M11.25 21.4498V20.4691C9.16672 20.2549 7.41288 19.4356 5.98855 18.0113C4.56422 16.587 3.74497 14.8331 3.5308 12.7498H2.55005C2.33755 12.7498 2.15947 12.6779 2.0158 12.5341C1.87197 12.3902 1.80005 12.2121 1.80005 11.9996C1.80005 11.7869 1.87197 11.6088 2.0158 11.4653C2.15947 11.3216 2.33755 11.2498 2.55005 11.2498H3.5308C3.74497 9.16647 4.56422 7.41264 5.98855 5.9883C7.41288 4.56397 9.16672 3.74472 11.25 3.53055V2.5498C11.25 2.3373 11.322 2.15922 11.4658 2.01555C11.6096 1.87172 11.7878 1.7998 12.0003 1.7998C12.213 1.7998 12.391 1.87172 12.5345 2.01555C12.6782 2.15922 12.75 2.3373 12.75 2.5498V3.53055C14.8334 3.74472 16.5872 4.56397 18.0115 5.9883C19.4359 7.41264 20.2551 9.16647 20.4693 11.2498H21.45C21.6625 11.2498 21.8406 11.3217 21.9843 11.4656C22.1281 11.6094 22.2 11.7876 22.2 12.0001C22.2 12.2127 22.1281 12.3908 21.9843 12.5343C21.8406 12.678 21.6625 12.7498 21.45 12.7498H20.4693C20.2551 14.8331 19.4359 16.587 18.0115 18.0113C16.5872 19.4356 14.8334 20.2549 12.75 20.4691V21.4498C12.75 21.6623 12.6781 21.8404 12.5343 21.9841C12.3905 22.1279 12.2123 22.1998 11.9998 22.1998C11.7871 22.1998 11.609 22.1279 11.4655 21.9841C11.3219 21.8404 11.25 21.6623 11.25 21.4498ZM12 18.9998C13.9334 18.9998 15.5834 18.3165 16.95 16.9498C18.3167 15.5831 19 13.9331 19 11.9998C19 10.0665 18.3167 8.41647 16.95 7.0498C15.5834 5.68314 13.9334 4.9998 12 4.9998C10.0667 4.9998 8.41672 5.68314 7.05005 7.0498C5.68338 8.41647 5.00005 10.0665 5.00005 11.9998C5.00005 13.9331 5.68338 15.5831 7.05005 16.9498C8.41672 18.3165 10.0667 18.9998 12 18.9998ZM12 15.4998C11.0375 15.4998 10.2136 15.1571 9.5283 14.4716C8.8428 13.7862 8.50005 12.9623 8.50005 11.9998C8.50005 11.0373 8.8428 10.2134 9.5283 9.52805C10.2136 8.84255 11.0375 8.4998 12 8.4998C12.9625 8.4998 13.7865 8.84255 14.4718 9.52805C15.1573 10.2134 15.5 11.0373 15.5 11.9998C15.5 12.9623 15.1573 13.7862 14.4718 14.4716C13.7865 15.1571 12.9625 15.4998 12 15.4998Z"
                        fill="#408E58"></path>
                </g>
            </svg>
        </div>

        <div class="mt-6 text-base border-t-[1px] border-[#d4d4d4] font-bold text-[#408e58] text-right">
            <p class="text-base font-bold text-right text-[#408e58]">وقت التوصيل</p>
            <p class="text-xs font-semibold text-right text-[#515151]">
                <span style="direction: ltr">{{ orderData.date }}</span>
                {{ orderData.timeRange }}
            </p>
        </div>

        <div class="flex flex-row-reverse border-t-[1px] border-[#d4d4d4] justify-end items-center mt-4 gap-2">
            <p class="text-xs font-semibold text-right text-[#515151]">
                للمساعدة, يرجى الإتصال 920007058
            </p>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6" preserveAspectRatio="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M16.6111 12.1116H16.6034C16.5231 12.1116 16.4581 12.0428 16.4619 11.9663C16.4963 10.9107 16.0833 9.92015 15.2992 9.17436C14.519 8.42857 13.4787 8.03464 12.3734 8.06523C12.2931 8.06523 12.2205 8.00786 12.2205 7.92755C12.2205 7.84723 12.2817 7.78221 12.3658 7.78221C13.5552 7.74779 14.672 8.17232 15.5134 8.97548C16.3548 9.77864 16.7946 10.8457 16.764 11.9778C16.7564 12.0543 16.6914 12.1155 16.6111 12.1155V12.1116Z"
                    fill="#408E58"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M17.9687 12.1118C17.8883 12.1118 17.8195 12.0468 17.8233 11.9665C17.8578 10.5629 17.3032 9.24721 16.2629 8.25664C15.2226 7.26608 13.842 6.73829 12.3733 6.76889C12.293 6.76889 12.2203 6.71152 12.2203 6.6312C12.2203 6.55089 12.2815 6.48587 12.3657 6.48587C13.9184 6.45145 15.3756 7.01366 16.4733 8.05777C17.5709 9.1057 18.1561 10.4978 18.1216 11.9779C18.1216 12.0544 18.049 12.1156 17.9687 12.1156V12.1118Z"
                    fill="#408E58"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M15.2533 12.1123H15.2456C15.1653 12.1123 15.1003 12.0396 15.1041 11.9631C15.1347 11.267 14.8593 10.5824 14.343 10.0929C13.8267 9.59951 13.1077 9.33562 12.3772 9.36621C12.2931 9.36621 12.2242 9.30885 12.2204 9.22853C12.2166 9.14821 12.2778 9.0832 12.3619 9.07937C13.1765 9.04495 13.972 9.33944 14.5495 9.89018C15.1271 10.4409 15.4368 11.202 15.3986 11.9784C15.3986 12.0511 15.3259 12.1123 15.2494 12.1123H15.2533Z"
                    fill="#408E58"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.31344 6.27948C7.28667 6.27948 7.25607 6.27948 7.23312 6.28713C6.70151 6.38657 5.71859 7.27387 5.47765 7.77871C5.11049 8.5398 5.31701 9.66423 6.06663 11.0334C6.75505 12.2917 7.87565 13.6877 9.2219 14.9766C10.572 16.2616 12.0368 17.3325 13.3524 17.9865C14.7905 18.7017 15.9684 18.8967 16.764 18.5487C17.2956 18.3192 18.2249 17.3784 18.3244 16.8735C18.397 16.5255 17.9228 15.7835 17.177 15.0683C16.4312 14.357 15.651 13.9057 15.2877 13.9745C14.7981 14.0663 14.3277 14.4258 14.1097 14.8771C14.0179 15.0645 13.8611 15.1984 13.6584 15.2596C13.4557 15.3208 13.2453 15.3055 13.0579 15.2022C11.5702 14.4067 9.82236 12.7354 8.9886 11.3203C8.88151 11.1405 8.86239 10.9416 8.93123 10.7466C9.00007 10.5515 9.13393 10.4024 9.33281 10.3144C9.80323 10.1079 10.1819 9.65658 10.2775 9.18998C10.3501 8.84194 9.8759 8.09998 9.13011 7.38478C8.43021 6.71548 7.70355 6.27948 7.31344 6.27948ZM15.8767 19C15.127 19 14.2244 18.7438 13.2109 18.2389C11.8685 17.5696 10.3769 16.4834 9.00772 15.1793C7.64235 13.8713 6.49881 12.4485 5.79891 11.1673C5.0034 9.71395 4.79687 8.50156 5.20228 7.66398C5.48147 7.08264 6.55235 6.13033 7.17193 6.01176C7.74562 5.90468 8.66351 6.5472 9.32899 7.18591C9.99446 7.82079 10.6676 8.69661 10.5567 9.24353C10.4458 9.79426 9.99446 10.3297 9.44372 10.5706C9.32134 10.6242 9.24102 10.7083 9.20278 10.8307C9.16453 10.9531 9.17218 11.0678 9.24102 11.1749C10.0365 12.5288 11.7767 14.1887 13.1956 14.9498C13.3142 15.011 13.4328 15.0224 13.559 14.9842C13.6852 14.946 13.777 14.8695 13.8305 14.7547C14.0829 14.2269 14.6451 13.7986 15.2226 13.6915C15.7963 13.5844 16.7142 14.2269 17.3797 14.8656C18.0452 15.5005 18.7221 16.3763 18.6112 16.9233C18.4888 17.5161 17.4906 18.5372 16.8825 18.8049C16.5918 18.935 16.2515 19 15.8767 19Z"
                    fill="#408E58"></path>
            </svg>
        </div>

        <div class="flex justify-center mt-4 items-center w-full">
            <button @click="showCanceledModal" class="text-base font-bold text-center text-[#f74848]">
                <span v-if="orderData.state !== 'Cancelled'">إلغاء الطلب</span>
            </button>
        </div>
    </div>
</div>
</template>


<style scoped>
.modal-container {
    transform: translateY(100%);
    transition: transform 50s ease-in-out;
    /* زيادة الوقت إلى 5 ثوانٍ */
}

.modal-container.translate-y-0 {
    transform: translateY(0);
}

.modal-container.translate-y-full {
    transform: translateY(100%);
}
</style>