import { createRouter, createWebHistory } from "vue-router";
import MainPage from "@/views/MainPage.vue";
import CategoryPage from "@/views/CategoryPage.vue";
import CartPage from "@/views/CartPage.vue";
import MyOrderPage from "@/views/MyOrderPage.vue";
import UserProfilePage from "@/views/UserProfilePage.vue";
import GestProfilePage from "@/views/GestProfilePage.vue";
import registerPage from "@/views/registerPage.vue";
import loginPage from "@/views/loginPage.vue";
import verifyPage from "@/views/verifyPage.vue";
import CartConfirmationPage from "@/views/CartConfirmationPage.vue";
import AboutCompanyPage from "@/views/AboutCompanyPage.vue";
import PrivacyPolicyPage from "@/views/PrivacyPolicyPage.vue";
import Addresses from "@/views/Addresses.vue";
import Map from "@/views/Map.vue";
import HyperPayForm from "@/views/HyperPayForm.vue";
const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/",
            name: "home",
            component: MainPage,
            meta: {
                requiresAuth: false,
                title: "الرئيسية",
            },
        },
        {
            path: "/categories",
            name: "categories",
            component: CategoryPage,
            meta: {
                requiresAuth: false,
                title: "المنتجات",
            },
        },
        {
            path: "/cart",
            name: "cart",
            component: CartPage,
            meta: {
                requiresAuth: false,
                title: "السلة",
            },
        },
        {
            path: "/user-profile-page",
            name: "user-profile-page",
            component: UserProfilePage,
            meta: {
                requiresAuth: true,
                title: "الملف الشخصي ",
            },
        },
        {
            path: "/gest-profile-page",
            name: "gest-profile-page",
            component: GestProfilePage,
            meta: {
                requiresAuth: false,
                mustBeGuest: true,
                title: "صفحة الزائر",
            },
        },
        {
            path: "/my-orders",
            name: "my-orders",
            component: MyOrderPage,
            meta: {
                requiresAuth: true,
                title: "الطلبات",
            },
        },
        {
            path: "/login",
            name: "login",
            component: loginPage,
            meta: {
                requiresAuth: false,
                mustBeGuest: true,
                title: "تسجيل الدخول",
            },
        },
        {
            path: "/registerPage",
            name: "registerPage",
            component: registerPage,
            meta: {
                requiresAuth: false,
                mustBeGuest: true,
                title: "حساب جديد",
            },
        },
        {
            path: "/verifyPage",
            name: "verifyPage",
            component: verifyPage,
            meta: {
                requiresAuth: false,
                mustBeGuest: true,
                title: "التحقق من الجوال",
            },
        },
        {
            path: "/addresses",
            name: "addresses",
            component: Addresses,
            meta: {
                requiresAuth: true,
                mustBeGuest: false,
                title: "العناوين",
            },
        },
        {
            path: "/map",
            name: "map",
            component: Map,
            meta: {
                requiresAuth: true,
                mustBeGuest: false,
                title: "الخارطة",
            },
        },
        {
            path: "/cart/confirmation",
            name: "cart-confirmation",
            component: CartConfirmationPage,
            meta: {
                requiresAuth: true,
                title: "تأكيد الطلب",
            },
        },
        {
            path: "/about",
            name: "about",
            component: AboutCompanyPage,
            meta: {
                requiresAuth: false,
                title: "عن الشركة",
            },
        },
        {
            path: "/privacy-policy",
            name: "privacy-policy",
            component: PrivacyPolicyPage,
            meta: {
                requiresAuth: false,
                title: "سياسات الخصوصية",
            },
        },
        {
            path: "/online-payment",
            name: "online-payment",
            component: HyperPayForm,
            meta: {
                requiresAuth: true,
                title: "الدفع الالكتروني",
            },
        },

    ],
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token");
    if (to.meta.requiresAuth) {
        if (token) {
            next();
        } else {
            next("/login");
        }
    }

    if (to.meta.mustBeGuest) {
        if (token) {
            next("/");
        } else {
            next();
        }
    }

    next();
});

const DEFAULT_TITLE = "طازج الغذائية";
router.afterEach((to) => {
    document.title = to.meta.title
        ? DEFAULT_TITLE + " | " + to.meta.title
        : DEFAULT_TITLE;
});

export default router;
