import { defineStore } from "pinia";
import ApiService from "@/service/apiService";
import IndexedDbService from "@/service/indexedDbService";


export const useProductsStore = defineStore({
    id: "products",
    state: () => ({
        categories: [],
        delivery: [],
        cities: [],
        payment_methods: [],
        fast_status_messages: [],
        feedback_questions: [],
        selectedAria: {
            name: "منطقة الرياض",
            id: 1
        },
        selectedCategoryId: 1,
        productDetails: null,
        SelectedCity: {},
        searchQuery: "",
        isLoading: false,
        isFastDelivery: false,
        dbService: null
    }),

    getters: {
        allCategories(state) {
            return state.categories;
        },

        selectedCategory(state) {
            return state.categories.find((c) => c.id === state.selectedCategoryId);
        },

        getSettingData() {
            return JSON.parse(localStorage.getItem("result"));
        },

        categoryProduct() {
            return this.selectedCategory?.categ_ids
                ? this.selectedCategory.categ_ids
                : [];
        },
    },

    actions: {
        turnOnLoading() {
            this.isLoading = true;
        },
        turnOffLoading() {
            this.isLoading = false;
        },
        setSearchQuery(query) {
            this.searchQuery = query;
        },
        emptySearch() {
            document.getElementById("searchInput").value = "";
            this.searchQuery = "";
        },
        selectCity(id) {
            const cities = this.getSettingData.cities;
            const selectedCity = cities.find(
                (selectedCity) => selectedCity.id === id
            );
            this.SelectedCity = selectedCity;
        },

        async handleProductData(isCached = true) {
            const dbVersion = await IndexedDbService.getVersion("tazej") ?? 1;

            this.dbService = new IndexedDbService("tazej", isCached ? dbVersion : dbVersion + 1); // Initialize the IndexedDbService
            this.dbService.openConnection().then(async db => {

                let data;
                let areaData
                if (!isCached) {
                    data = await this.getProductsData();
                    await delete data.id;
                    if (this.isFastDelivery) {
                        areaData = await data.filter(cat => cat.analytic_account_ids && cat.analytic_account_ids.includes(this.selectedAria.id) && cat.is_fastdelivery);

                    } else {
                        areaData = await data.filter(cat => cat.analytic_account_ids && cat.analytic_account_ids.includes(this.selectedAria.id) && !cat.is_fastdelivery);
                    }
                    areaData.id = 0;
                    db.clear("categories");
                    db.add("categories", areaData);

                } else {

                    data = await db.get("categories", 0);
                    await delete data.id;
                    this.fillData();

                    if (this.isFastDelivery) {
                        areaData = Object.values(await data).filter(cat => cat.analytic_account_ids && cat.analytic_account_ids.includes(this.selectedAria.id) && cat.is_fastdelivery);
                    } else {
                        areaData = Object.values(await data).filter(cat => cat.analytic_account_ids && cat.analytic_account_ids.includes(this.selectedAria.id) && !cat.is_fastdelivery);
                    }
                }

                this.categories = areaData;
            }).catch(error => {
                console.error('Failed to open database connection:', error);
            });




        },
        fillData(data) {
            const delivery = {
                delivery_availabilities: data?.delivery_availabilities ?? JSON.parse(localStorage.getItem("delivery")).delivery_availabilities,
                delivery_fees: data?.delivery_fees ?? JSON.parse(localStorage.getItem("delivery")).delivery_fees,
                delivery_periods: data?.delivery_periods ?? JSON.parse(localStorage.getItem("delivery")).delivery_periods,
            };

            this.delivery = delivery;
            this.cities = data?.cities ?? JSON.parse(localStorage.getItem("cities"));
            this.payment_methods = data?.payment_methods ?? JSON.parse(localStorage.getItem("payment_methods"));
            this.fast_status_messages = data?.fast_status_messages ?? JSON.parse(localStorage.getItem("fast_status_messages"));
            this.feedback_questions = data?.feedback_questions ?? JSON.parse(localStorage.getItem("feedback_questions"));
            this.selectedAria = data?.cities.filter((city) => city.id === 1)[0] ?? JSON.parse(localStorage.getItem("selectedAria"));
        },

        async getProductsData() {
            try {
                this.isLoading = true; // Start loading
                const response = await ApiService.post("v3/endpoint/get_products_data", {
                    params: {},
                });

                if (response?.data?.result) {
                    const delivery = {
                        delivery_availabilities: response?.data?.result?.delivery_availabilities,
                        delivery_fees: response?.data?.result?.delivery_fees,
                        delivery_periods: response?.data?.result?.delivery_periods,
                    };

                    localStorage.setItem("delivery", JSON.stringify(delivery));
                    localStorage.setItem("cities", JSON.stringify(response?.data?.result.cities));
                    localStorage.setItem("payment_methods", JSON.stringify(response?.data?.result.payment_methods));
                    localStorage.setItem("fast_status_messages", JSON.stringify(response?.data?.result.fast_status_messages));
                    localStorage.setItem("feedback_questions", JSON.stringify(response?.data?.result.feedback_questions));
                    localStorage.setItem("selectedAria", JSON.stringify(response?.data?.result.cities.filter((city) => city.id === 1)[0]));

                    this.fillData(response?.data?.result);

                    return response?.data?.result.categories;

                } else {
                    console.error("Failed to fetch products data."); // Error handling
                }
            } catch (error) {
                console.error("Error fetching products data:", error); // Error handling
            } finally {
                this.isLoading = false; // End loading
            }

        },

        async getVersionParameter() {
            try {
                const response = await ApiService.post("v3/endpoint/version_parameter", {
                    params: {},
                });

                return await response?.data?.result?.version_parameter;// Return the extracted version
            } catch (error) {
                console.error("Failed to fetch version parameter.", error); // Error handling
            }
        },
        async fetchVersionAndUpdateProducts() {
            const versionFromAPI = await this.getVersionParameter(); // Get the version from the API
            const storedVersion = localStorage.getItem("VersionParameter"); // Get the version from localStorage

            if (versionFromAPI) {
                if (
                    storedVersion &&
                    storedVersion === versionFromAPI
                ) {
                    this.handleProductData();
                } else {
                    localStorage.setItem("VersionParameter", versionFromAPI);
                    this.handleProductData(false); // Fetch products data if versions differ
                }
            } else {
                console.error("Could not retrieve version from API."); // Error handling
            }
        },
        fetchProductDetails(id) {
            // Get categories from localStorage
            const categoriesData = localStorage.getItem("categories");

            // Parse the categories data if it exists
            const categories = categoriesData ? JSON.parse(categoriesData) : [];

            // Initialize productDetails to null
            this.productDetails = null;

            // Iterate through each category to find the product
            for (const category of categories) {

                // Check if category has categ_ids and find the product
                if (category.categ_ids) {
                    const product = category.categ_ids.find(
                        (product) => product.id === id
                    );

                    if (product) {
                        this.productDetails = product; // Store found product
                        break; // Exit the loop once the product is found
                    }
                }
            }

            // If no product found, log a message
            if (!this.productDetails) {
                console.log("No product found with the given ID.");
            }
        },
    },
});
