import { defineStore } from "pinia";
import { getDiscountedPrice } from "@/helpers/pricing";
export const useCartStore = defineStore({
    id: "store",

    state: () => ({
        products: localStorage.getItem("cart_tazj_product")
            ? JSON.parse(localStorage.getItem("cart_tazj_product"))
            : [],
        showWarningPopup: false, // State variable for the warning popup
        DeliveryConflict: false, // New state variable for delivery conflict
        DeliveryType: "",
        lastOrderId: null,
        checkoutId: null,
        integrity: null,
        onlinePayMentMethod: "visa"
    }),

    getters: {
        productGroupById: (state) => {
            return state.products.reduce((acc, obj) => {
                const group = acc.find((group) => group.id === obj.id);
                if (!group) {
                    acc.push(obj);
                }
                return acc;
            }, []);
        },


        totalPrice: (state) => {
            const subtotal = state.products.reduce((total, product) => {
                const price = typeof product.minimum_price === "number" && product.minimum_price > 0
                    ? product.minimum_price
                    : 0;

                const discountPercent = typeof product.discount_percent === "number" && product.discount_percent >= 0
                    ? product.discount_percent
                    : 0;

                const discountedPrice = parseFloat(getDiscountedPrice(price, discountPercent));

                return total + discountedPrice;
            }, 0);

            return subtotal.toFixed(2);
        },

    },

    actions: {
        addToCart(product) {
            this.products.push(product);
            this.updateLocalStorage();
            this.checkForDeliveryConflict(); // Check for delivery conflict after adding
        },

        addToCartData(productData) {
            this.products.push(productData);
            this.updateLocalStorage();
            this.checkForDeliveryConflict(); // Check for delivery conflict after adding
        },

        removeFromCart(id) {
            const indexToRemove = this.products.findIndex(
                (product) => product.id == id
            );

            if (indexToRemove !== -1) {
                this.products.splice(indexToRemove, 1);
                this.updateLocalStorage();
                this.checkForDeliveryConflict(); // Check for delivery conflict after removing
            }
        },

        removeItem(id) {
            this.products = this.products.filter((product) => product.id !== id);
            this.updateLocalStorage();
            this.checkForDeliveryConflict();
        },

        editCartItem(updatedProduct) {
            const indexToEdit = this.products.findIndex(
                (product) => product.id == updatedProduct.id
            );
            if (indexToEdit !== -1) {
                this.products[indexToEdit] = {
                    ...this.products[indexToEdit],
                    ...updatedProduct,
                };
                this.updateLocalStorage();
                this.checkForDeliveryConflict(); // Check for delivery conflict after editing
            }
        },

        clearCart() {
            this.products = [];
            this.showWarningPopup = false; // Reset the warning popup state when clearing the cart
            this.DeliveryConflict = false; // Reset delivery conflict state
            this.updateLocalStorage();
        },

        updateLocalStorage() {
            localStorage.setItem("cart_tazj_product", JSON.stringify(this.products));
        },

        checkForDeliveryConflict() {
            if (this.products.length === 0) {
                this.DeliveryConflict = false; // No products, no conflict
                this.showWarningPopup = false; // No conflict, hide popup
                return;
            }

            // Get all fast delivery statuses from the products
            const fastDeliveryStatuses = this.products.map((p) => p.is_fastdelivery);

            // Check if there's at least one fast delivery and one non-fast delivery product
            const hasFastDelivery = fastDeliveryStatuses.includes(true);
            const hasNormalDelivery = fastDeliveryStatuses.includes(false);

            // Set DeliveryConflict and showWarningPopup based on delivery conflict
            this.DeliveryConflict = hasFastDelivery && hasNormalDelivery;
            this.showWarningPopup = this.DeliveryConflict; // Show popup if there's a conflict
        },
        checkDeliveryType() {
            if (this.products.length === 0) {
                return;
            }

            // Get all fast delivery statuses from the products
            const fastDeliveryStatuses = this.products.map((p) => p.is_fastdelivery);

            // Check if there's at least one fast delivery and one non-fast delivery product
            const hasFastDelivery = fastDeliveryStatuses.includes(true);
            const hasNormalDelivery = fastDeliveryStatuses.includes(false);

            if (hasFastDelivery) {
                this.DeliveryType = "FastDelivery";
            } else if (hasNormalDelivery) {
                this.DeliveryType = "NormalDelivery";
            }
        },
    },
});
