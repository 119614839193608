<template>
<div v-if="show" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    @click="$emit('close')">
    <div class="bg-white rounded-2xl w-[342px] h-[801px] relative overflow-hidden" @click.stop>
        <button class="absolute top-2 right-2 text-xl font-bold text-gray-500 hover:text-gray-800" v-if="closeable"
            @click="$emit('close')">
            &times;
        </button>

        <div class="relative w-full h-full">
            <!-- صورة المنتج -->
            <img :src="product.image
                ? 'data:image/png;base64,' + product.image
                : '/public/img/itemImage.png'
                " class="w-[342px] h-56 rounded-2xl object-cover" alt="Product" />

            <!-- معلومات المنتج -->
            <div class="w-[310px] h-[100px] absolute left-4 top-[150px] rounded-2xl"
                style="filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.18))">
                <div class="w-[310px] h-[100px] absolute left-[-1px] top-[-1px] rounded-2xl bg-white"></div>
                <div class="flex flex-col justify-start items-end w-[310px] absolute right-0 top-4 px-4">
                    <p class="text-sm font-semibold text-right text-[#515151] w-full">
                        {{ product.name }}
                    </p>
                    <p class="text-[10px] text-right text-[#515151] w-full">يبدأ السعر من</p>
                    <p class="text-sm font-bold text-right text-[#515151] w-full">
                        <span>
                            {{
                                product.discount_percent > 0
                                    ? discountedPrice + " ريال"
                                    : ((product.variants[0].list_price ?? 0) * 1.15).toFixed(2) + " ريال"
                            }}
                        </span>
                    </p>
                    <p class="text-[10px] font-semibold text-right text-[#9c9c9c] w-full">
                        شامل ضريبة القيمة المضافة 15%
                    </p>
                </div>
            </div>

            <div class="w-[310px] h-[86px] absolute left-4 top-[260px]">
                <p class="w-[244px] absolute left-[66px] top-0 text-xs font-semibold text-right text-[#9c9c9c]">
                    كيلو, يستخرج أجود أنواع الحاشي البلدي (المفرود) ليصلك طازج
                </p>

                <svg @click="increaseProduct()" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 absolute left-[75px] top-[53px]"
                    preserveAspectRatio="xMidYMid meet">
                    <rect width="24" height="24" rx="8" fill="#408E58"></rect>
                    <circle cx="12" cy="12" r="7.5" stroke="white"></circle>
                    <path
                        d="M11.4872 12.5128H8.06836C7.92306 12.5128 7.8013 12.4636 7.70306 12.3653C7.60472 12.2669 7.55554 12.1451 7.55554 11.9998C7.55554 11.8544 7.60472 11.7326 7.70306 11.6345C7.8013 11.5363 7.92306 11.4872 8.06836 11.4872H11.4872V8.06836C11.4872 7.92306 11.5363 7.8013 11.6347 7.70306C11.733 7.60472 11.8549 7.55554 12.0002 7.55554C12.1456 7.55554 12.2673 7.60472 12.3655 7.70306C12.4637 7.8013 12.5128 7.92306 12.5128 8.06836V11.4872H15.9316C16.0769 11.4872 16.1987 11.5363 16.2969 11.6347C16.3953 11.733 16.4444 11.8549 16.4444 12.0002C16.4444 12.1456 16.3953 12.2673 16.2969 12.3655C16.1987 12.4637 16.0769 12.5128 15.9316 12.5128H12.5128V15.9316C12.5128 16.0769 12.4636 16.1987 12.3653 16.2969C12.2669 16.3953 12.1451 16.4444 11.9998 16.4444C11.8544 16.4444 11.7326 16.3953 11.6345 16.2969C11.5363 16.1987 11.4872 16.0769 11.4872 15.9316V12.5128Z"
                        fill="white"></path>
                </svg>
                <p class="absolute left-[55px] top-[52px] px-1 text-sm font-bold text-center text-[#515151]">
                    {{ productCounter }}

                </p>
                <svg @click="removeProductFromCart()" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg" class="w-6 h-6 absolute left-[27px] top-[53px]"
                    preserveAspectRatio="xMidYMid meet">
                    <rect width="24" height="24" rx="8" fill="#408E58"></rect>
                    <circle cx="12" cy="12" r="7.5" stroke="white"></circle>
                    <path
                        d="M7.57692 13C7.41346 13 7.27647 12.9521 7.16596 12.8562C7.05532 12.7603 7 12.6415 7 12.4998C7 12.3581 7.05532 12.2393 7.16596 12.1437C7.27647 12.0479 7.41346 12 7.57692 12H16.4231C16.5865 12 16.7235 12.0479 16.834 12.1438C16.9447 12.2397 17 12.3585 17 12.5002C17 12.6419 16.9447 12.7607 16.834 12.8563C16.7235 12.9521 16.5865 13 16.4231 13H7.57692Z"
                        fill="white"></path>
                </svg>
                <p class="absolute left-[247px] top-[52px] text-sm font-bold text-right text-[#515151]">
                    {{
                        (product.variants[0].list_price * productCounter).toFixed(2) + " ريال"
                    }} </p>
            </div>

            <div v-if="
                product.variants.some(
                    (variant) => variant.cut_ids?.length || variant.option_ids?.length
                )
            " class="flex flex-col absolute left-[82px] mt-2 top-[400px]">
                <!-- Loop through variants -->
                <div v-for="variant in product.variants" :key="variant.id" class="flex flex-col gap-1 w-full">
                    <!-- Cutting Options -->
                    <div v-if="variant.cut_ids?.length" class="my-1 mx-2">
                        <p class="text-sm font-bold text-[#515151] mx-2 mt-2">أنواع التقطيع:</p>
                        <div class="grid grid-cols-3 gap-2">
                            <label v-for="cut in variant.cut_ids" :key="cut.id"
                                class="flex items-center gap-2 p-2 rounded-lg transition cursor-pointer">
                                <input type="radio" :value="cut.id" v-model="selectedOptions[variant.id]"
                                    class="form-radio text-yellow-500 focus:ring-yellow-500" />
                                <p class="text-sm text-nowrap font-semibold">
                                    {{ cut.name }}
                                </p>
                            </label>
                        </div>
                    </div>
                    <!-- Additional Options -->
                    <div v-show="variant.option_ids?.length" class="mt-0">
                        <p class="text-sm mx-2 font-bold text-[#515151]">خيارات إضافية:</p>
                        <div class="grid grid-cols-2 gap-1">
                            <label v-for="option in variant.option_ids" :key="option.id" class="flex items-center p-2">
                                <input type="radio" :value="option.id" v-model="selectedOptions[`${variant.id}-option`]"
                                    class="form-radio text-gray-500 focus:ring-gray-500 checked:text-orange-500" />
                                <p class="text-sm font-semibold text-nowrap">
                                    {{ option.name }}
                                </p>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="w-[307px] h-[104px] mt-1 absolute left-4" :style="{
                top: product.variants.some((variant) => variant.option_ids?.length)
                    ? '580px'
                    : '540px',
            }">
                <div class="w-[307px] h-[104px] bg-[#f4f4f4] absolute left-0 top-0"></div>
                <p class="absolute left-[215px] top-2 text-xs text-right text-[#515151]">
                    أضف ملاحظتك:
                </p>
                <textarea v-model="note" placeholder="اكتب ملاحظتك"
                    class="w-[275px] h-16 absolute left-4 top-[30px] border border-[#9c9c9c] text-right text-[10px] text-[#9c9c9c] p-2 focus:outline-none focus:border-[#515151]"></textarea>
            </div>

            <div class="flex justify-start mt-1 items-center absolute left-4 top-[680px] gap-[17px]">
                <button @click="addProductDataToCart(product)"
                    class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[138px] h-9 relative gap-2 px-4 py-[11px] rounded-lg bg-[#408e58]">
                    <p class="flex-grow-0 flex-shrink-0 text-sm text-center text-white">
                        أَضف للسلة
                    </p>
                </button>
                <svg width="1" height="36" viewBox="0 0 1 36" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="flex-grow-0 flex-shrink-0" preserveAspectRatio="none">
                    <line x1="0.25" y1="-1.09278e-8" x2="0.250002" y2="36" stroke="#9C9C9C" stroke-width="0.5">
                    </line>
                </svg>
                <button @click="$emit('close')"
                    class="flex justify-center items-center flex-grow-0 flex-shrink-0 w-[138px] h-9 relative gap-2 px-4 py-[11px] rounded-lg bg-[#408e58]">
                    <p class="flex-grow-0 flex-shrink-0 text-sm text-center text-white">
                        أكمل التسوق
                    </p>
                </button>
            </div>
        </div>
        <!-- Closing tag added here -->
    </div>
</div>
</template>

<script setup>
import { defineProps, watch } from "vue";
import { useRouter } from "vue-router";
import { useCartStore } from "@/stores/cart.store";
import { useProductsStore } from "@/stores/products.store";
const props = defineProps({
    show: Boolean,
    product: Object,
    maxWidth: String,
    closeable: Boolean,
});
const selectedOptions = reactive({});
const productCounter = ref(1);



const router = useRouter();

const addProductDataToCart = (product) => {

    if (!product) {
        console.error("Product is undefined or null.");
        return;
    }
    if (Array.isArray(product.variants) && product.variants.length > 0) {
        removeAllProductsFromCart(product.id);

        product.variants[0].cut_id = selectedOptions[product.variants[0].id] ?? product.variants[0]?.cut_ids[0];
        product.variants[0].option_id = selectedOptions[`${product.variants[0].id}-option`] ?? [];

        useCartStore().addToCartData(product);

        productCounter.value = 1;
        useProductsStore().emptySearch();
        router.push("/cart");
    } else {
        console.warn("This product does not have any variants.");
    }
};

const getDiscountedPrice = (price, discountPercent) => {
    if (typeof price !== "number" || price < 0) {
        console.error("خطأ: السعر غير صحيح");
        return 0;
    }
    if (
        typeof discountPercent !== "number" ||
        discountPercent < 0 ||
        discountPercent > 100
    ) {
        console.error("خطأ: نسبة الخصم غير صحيحة");
        return price;
    }

    if (discountPercent > 0) {
        const discount = discountPercent;
        const priceWithTax = price * 1.15;
        const discountWithTax = discount * 1.15;
        const finalPrice = priceWithTax - discountWithTax;
        return finalPrice.toFixed(2);
    }
    return (price * 1.15).toFixed(2);
};

const discountedPrice = computed(() => {
    const price = props.productData?.variants[0].list_price ?? 0;
    const discountPercent = props.productData?.discount_percent ?? 0;
    return getDiscountedPrice(price, discountPercent);
});

const searchProductInCart = (id) => {
    const productCount = useCartStore().products.filter((product) => product.id === id).length;
    return productCount === 0 ? 0 : productCount;
};

// On mounted hook
onMounted(() => {
    const savedProducts = localStorage.getItem("cart_tazj_product");
    if (savedProducts) {
        try {
            const parsedData = JSON.parse(savedProducts);
            if (Array.isArray(parsedData)) {
                parsedData.forEach((product) => {
                    if (Array.isArray(product.variants)) {
                        product.variants.forEach((variant) => {
                            if (variant && variant.id !== undefined && variant.cut_id !== undefined) {
                                selectedOptions[variant.id] = variant.cut_id;
                            }
                            if (variant.id !== undefined && variant.option_id !== undefined) {
                                selectedOptions[`${variant.id}-option`] = variant.option_id;
                            }
                            if (variant.id !== undefined && variant.note !== undefined) {
                                notes[variant.id] = variant.note;
                            }
                        });
                    }
                });
            } else {
                console.warn("Parsed data is not an array:", parsedData);
            }
        } catch (error) {
            console.error("Failed to parse saved products, resetting localStorage:", error);
            localStorage.removeItem("cart_tazj_product"); // حذف البيانات غير الصالحة
        }
    }
});

function increaseProduct() {
    productCounter.value++;
}

watch(
    () => props.product,
    (newVal) => {
        if (newVal.id) {
            productCounter.value = searchProductInCart(newVal.id) > 0 ? searchProductInCart(newVal.id) : 1;
        }
    }
);

// Watch for changes in selectedOptions and save to localStorage
watch(
    selectedOptions,
    (newOptions) => {
        localStorage.setItem("selectedOptions", JSON.stringify(newOptions));
    },
    { deep: true }
);

const removeProductFromCart = () => {
    if (productCounter.value > 1) {
        productCounter.value--;
    }
};

function removeAllProductsFromCart(id) {
    const productCount = searchProductInCart(id);
    for (let i = 0; i < productCount; i++) {
        useCartStore().removeFromCart(id);
    }
}

</script>

<style>
/* In your custom CSS file or inside a <style> block */
@media (max-width: 640px) {
    .custom-model {
        width: 360px;
        height: 740px;
    }
}

@media (min-width: 641px) and (max-width: 768px) {
    .custom-model {
        width: 400px;
        height: 600px;
    }
}

@media (min-width: 769px) {
    .custom-model {
        width: 450px;
        height: 700px;
    }
}

/* الحاوية الرئيسية للخيارات */
.options-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* ترتيب العناصر في شبكة بحيث كل 3 عناصر على سطر واحد */
.options-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    /* المسافة بين العناصر */
}

.option-item {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: background-color 0.3s;
    flex-basis: calc(33.333% - 15px);
    /* كل عنصر يأخذ ثلث العرض مع مراعاة المسافات */
    box-sizing: border-box;
    /* لضمان أن الحواف تحسب ضمن الحجم الكلي */
}

.option-item:hover {
    background-color: #f0f0f0;
}

.option-item svg {
    flex-shrink: 0;
}
</style>
