<template>
<div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="max-w-md w-full p-6 border border-gray-300 rounded-lg bg-white shadow-md">
        <h1 class="text-2xl font-bold text-center mb-6">Online Payment Form</h1>
        <form action="https://www.tazejfood.com/" class="paymentWidgets"
            :data-brands="cartStore.onlinePayMentMethod.toUpperCase()"></form>
    </div>
</div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useCartStore } from '@/stores/cart.store';

const cartStore = useCartStore();

onMounted(() => {
    // Define wpwlOptions before loading the script
    window.wpwlOptions = {
        style: "card",
        applePay: {
            displayName: "Tazaj Food, Inc.",
            total: { label: "TAZAJ FOOD, INC." }
        }
    };

    const script = document.createElement('script');
    script.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${cartStore.checkoutId}`;
    script.crossOrigin = "anonymous";

    script.async = true;
    document.body.appendChild(script);
});



</script>
