<script setup>
import { useCartStore } from "@/stores/cart.store";
import { useProductsStore } from "@/stores/products.store";
import { computed } from "vue";
// Props
const props = defineProps({
    productData: Object,
});

const emit = defineEmits(["click"]);

const handleClick = () => {
    emit("click", props.productData);
};

// Cart functions
const productSearch = (id) => {
    return useCartStore().products.filter((product) => product.id === id).length;
};

const addProductToCart = (product) => {
    useCartStore().addToCart(product);
    useProductsStore().emptySearch();
};

const removeProductFromCart = (id) => {
    useCartStore().removeFromCart(id);
};

const getDiscountedPrice = (price, discountPercent) => {
    if (typeof price !== "number" || price < 0) {
        console.error("خطأ: السعر غير صحيح");
        return 0;
    }
    if (
        typeof discountPercent !== "number" ||
        discountPercent < 0 ||
        discountPercent > 100
    ) {
        console.error("خطأ: نسبة الخصم غير صحيحة");
        return price;
    }

    if (discountPercent > 0) {
        const discount = discountPercent;
        const priceWithTax = price * 1.15;
        const discountWithTax = discount * 1.15;
        const finalPrice = priceWithTax - discountWithTax;
        return finalPrice.toFixed(2);
    }
    return (price * 1.15).toFixed(2);
};

const discountedPrice = computed(() => {
    const price = props.productData?.minimum_price ?? 0;
    const discountPercent = props.productData?.discount_percent ?? 0;
    return getDiscountedPrice(price, discountPercent);
});
</script>
<template>
<div class="flex flex-col justify-start items-start w-24 gap-2">
    <div @click="handleClick"
        class="flex flex-col justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-0.5">
        <img class="self-stretch flex-grow-0 flex-shrink-0 h-[104px] rounded-2xl object-cover" :src="productData.image
            ? 'data:image/png;base64,' + productData.image
            : '/public/img/itemImage.png'
            " draggable="false" />

        <span class="font-medium text-xs text-nowrap">{{ productData.name }}</span>
    </div>
    <div class="w-full text-nowrap flex gap-1 text-xs font-semibold text-tazej-gray-400">
        <!-- عرض السعر المخفض أو السعر الأصلي -->
        <span class="font-semibold text-center text-[#515151]">
            {{ discountedPrice + " ريال" }}
        </span>

        <!-- خط فاصل إذا كان هناك خصم -->
        <svg v-if="productData.discount_percent > 0" width="1" height="16" viewBox="0 0 1 16" fill="none"
            xmlns="http://www.w3.org/2000/svg" class="flex-grow-0 flex-shrink-0" preserveAspectRatio="none">
            <line x1="0.25" y1="-1.09278e-8" x2="0.250001" y2="16" stroke="#515151" stroke-width="0.5"></line>
        </svg>

        <!-- عرض السعر الأصلي مع خط عليه إذا كان هناك خصم -->
        <span v-if="productData.discount_percent > 0" class="text-red-400 text-xs"
            style="display: inline-block; position: relative">
            <span style="
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: red;
            top: 50%;
            left: 0;
            transform: rotate(-10deg);
          "></span>
            {{ (productData.minimum_price * 1.15).toFixed(2) + " ر.س" }}
        </span>
    </div>
    <!-- إذا لم يكن has_cut موجودًا -->
    <div class="w-full cursor-pointer" v-if="!productData?.variants[0]?.has_cut && productSearch(productData.id) == 0">
        <button @click="addProductToCart(productData)"
            class="block pt-1 pb-2 font-semibold text-tazej-lightGreen-primary text-center w-full rounded-[999px] border border-tazej-lightGreen-primary text-xs">
            أضف للسلة
        </button>
    </div>

    <!-- إذا لم يكن has_cut موجودًا وكان المنتج في السلة -->
    <div class="w-full mt-1" v-else-if="!productData?.variants[0]?.has_cut">
        <div
            class="p-1 font-semibold text-center w-full text-white rounded-full border border-tazej-text-tazej-lightGreen-primary text-xs bg-tazej-lightGreen-primary flex justify-center gap-2">
            <button class="border border-white p-1 w-5 h-5 rounded-full flex items-center justify-center"
                @click="addProductToCart(productData)">
                <font-awesome-icon class="text-white" :icon="['fas', 'plus']" />
            </button>
            <span class="font-semibold">{{ productSearch(productData.id) }}</span>
            <button class="border border-white p-1 w-5 h-5 rounded-full flex items-center justify-center"
                @click="removeProductFromCart(productData.id)">
                <font-awesome-icon class="text-white" :icon="['fas', 'minus']" />
            </button>
        </div>
    </div>

    <!-- إذا كان has_cut موجودًا -->
    <div v-else class="w-full my-2">
        <div class="w-full cursor-pointer">
            <button @click="handleClick"
                class="block pt-1 pb-2 font-semibold text-tazej-lightGreen-primary text-center w-full rounded-xl border border-tazej-lightGreen-primary text-xs">
                خصص طلبك
            </button>
        </div>
    </div>
</div>
</template>
