import ApiService from "./apiService";
import MainService from "./mainService";

class OrderService extends MainService {
    constructor() {
        super();
    }

    async addNewOrder(order) {

        return await ApiService.post('v3/sales/order/create', {
            params: {
                "order_data": {

                    "partner_id": order.partner_id,
                    "address_id": order.address_id,
                    "analytic_account_id": 1,
                    "origin": "web",
                    "delivery_day": order.delivery_day,
                    "date": order.date,
                    "coupon": order.coupon,
                    "delivery_time_type": "period",
                    "delivery_period": Number(order.delivery_period),
                    "carrier_id": 1,
                    "payment_method": "cash",
                    "products": order.products
                }
            }
        });

    }


    async addNewGiftOrder(order) {

        return await ApiService.post('sales/gift/order/create', {
            params: {
                "order_data": {
                    "partner_id": order.partner_id,
                    "analytic_account_id": 1,
                    "origin": "web",
                    "delivery_day": order.delivery_day,
                    "date": order.date,
                    "coupon": order.coupon,
                    "delivery_time_type": "period",
                    "delivery_period": Number(order.delivery_period),
                    "carrier_id": 1,
                    "payment_method": "cash",
                    "products": order.products,
                    "neighborhood_id": 288,
                    "neighborhood_txt": "",
                    "is_gift": true,
                    "gift_recipient": order.gift_recipient,
                    "gift_sender": order.gift_sender,
                    "recipient_phone": order.recipient_phone,
                    "gift_note": order.gift_note,
                    "is_donation": false,
                    "charity_org_id": false,
                }
            }
        });
    }


    async addNewDonationOrder(order) {

        return await ApiService.post('sales/gift/order/create', {
            params: {
                "order_data": {
                    "partner_id": order.partner_id,
                    "analytic_account_id": 1,
                    "origin": "web",
                    "delivery_day": order.delivery_day,
                    "date": order.date,
                    "coupon": order.coupon,
                    "delivery_time_type": "period",
                    "delivery_period": Number(order.delivery_period),
                    "carrier_id": 1,
                    "payment_method": "cash",
                    "products": order.products,
                    "neighborhood_id": "",
                    "neighborhood_txt": "",
                    "is_gift": false,
                    "gift_recipient": "",
                    "gift_sender": "",
                    "recipient_phone": "",
                    "gift_note": "",
                    "is_donation": true,
                    "charity_org_id": order.charity_org_id,

                }
            }
        });
    }

    async checkCoupon(coupon, products) {

        return await ApiService.post('sales/promotion/validity', {
            params: {
                "products": products,
                "partner_id": coupon.partner_id,
                "code": coupon.code,

            }
        });
    }


    async getCurrentOrders(partnerId) {

        return await ApiService.post('sales/order/get_partner_orders', {
            params: {
                "partner_id": partnerId,
                "order_type": "future"

            }

        });
    }

    async getPastOrders(partnerId) {

        return await ApiService.post('sales/order/get_partner_orders', {
            params: {
                "partner_id": partnerId,
                "order_type": "past"
            }

        });
    }

    async getOrderDetails(orderId) {

        return await ApiService.post('sales/order/order_summary', {
            params: {
                "order_id": orderId
            }

        });
    }
    async cancelOrder(orderId) {
        return await ApiService.post(`sales/order/cancel`, {
            params: {
                "order_id": orderId
            }

        });
    }

    async requestPayment(orderId, onlinePaymentMethod) {
        return await ApiService.post(`web/payment/hyperpay/request`, {
            params: {
                "payment_method": onlinePaymentMethod,
                "order_id": orderId
            }

        });
    }
    async tamaraPayment(order_id, instalments) {
        return await ApiService.post(`web/payment/tamara/checkout_session`, {
            params: {
                payment_type: "PAY_BY_INSTALMENTS",
                order_id,
                instalments
            }

        });
    }
    async addRating(ratingData) {
        return await ApiService.post('sales/order/post_feedback', {
            params: ratingData,
        });
    }
    async getTamaraInstallment(order_value, phone) {
        return await ApiService.post('payment/tamara/get_available_methods', {
            params: {
                order_value,
                phone
            }

        });
    }

}

export default new OrderService();
