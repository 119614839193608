<template>
<div class="m-0 p-0">
    <div class="relative w-full h-48 lg:h-[50rem] overflow-hidden m-0 p-0 box-border"
        style="line-height: 0; font-size: 0;" @touchstart="onTouchStart" @touchmove="onTouchMove"
        @touchend="onTouchEnd">
        <div v-for="(image, index) in images" :key="index"
            :class="['absolute top-0 left-0 w-full h-full transform transition-transform duration-1000', getSlideClass(index)]">
            <img v-if="isImagesFromServer" :src="'data:image/png;base64,' + image" alt="Slider Image"
                class="w-full h-full object-cover lg:object-contain block m-0 p-0 align-bottom" />
            <img v-else :src="image" alt="Slider Image"
                class="w-full h-full object-cover lg:object-contain block m-0 p-0 align-bottom" />
        </div>
    </div>
</div>
</template>

<script setup>
import slider1 from "@/assets/slider1.jpeg";
import slider2 from "@/assets/slider2.jpeg";
import mainService from "@/service/mainService";
import { ref, onMounted } from 'vue';


let images = [slider1, slider2];
const currentIndex = ref(0);
const touchStartX = ref(0);
const touchEndX = ref(0);
const isImagesFromServer = ref(false);
async function getImages() {
    const slidersData = await mainService.getSlider();
    console.log(slidersData);

    if (slidersData?.data?.result?.length > 0) {
        isImagesFromServer.value = true;
        images = [];
        slidersData.data.result.forEach((slider) => {
            images.push(slider.image);
        });
    }
}

const getSlideClass = (index) => {
    if (index === currentIndex.value) {
        return 'translate-x-0';
    } else if (index < currentIndex.value) {
        return '-translate-x-full';
    } else {
        return 'translate-x-full';
    }
};

const onTouchStart = (event) => {
    touchStartX.value = event.touches[0].clientX;
};

const onTouchMove = (event) => {
    touchEndX.value = event.touches[0].clientX;
};

const onTouchEnd = () => {
    const deltaX = touchEndX.value - touchStartX.value;
    if (deltaX > 50) {
        // السحب من اليمين إلى اليسار: التحرك إلى الصورة السابقة
        currentIndex.value = (currentIndex.value - 1 + images.length) % images.length;
    } else if (deltaX < -50) {
        // السحب من اليسار إلى اليمين: التحرك إلى الصورة التالية
        currentIndex.value = (currentIndex.value + 1) % images.length;
    }
};

onMounted(() => {
    setTimeout(() => {
        getImages();
    });
    setInterval(() => {
        currentIndex.value = (currentIndex.value + 1) % images.length;
    }, 3000);
});


</script>
